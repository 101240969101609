<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card
      tile
      flat
    >
      <v-toolbar
        dark
        flat
        color="white"
      ></v-toolbar>
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <h1 class="primary--text hidden-xs-only" style="font-family: Roboto;font-size: 2.2rem;font-weight: 900;">주문내역</h1>
          <h3 class="primary--text d-flex d-sm-none" style="font-family: Roboto;font-size: 1.5rem;font-weight: 900;">주문내역</h3>
          <div class="mb-6">
            <span class="text-subtitle-2 error--text" style="font-size: 1.5rem;font-weight: 600;">✻ 무통장결제 입금대기 페이지 입니다.</span>
          </div>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <span class="text-h6 primary--text font-weight-bold">기본내역</span>
          <v-progress-linear
            :indeterminate="isProgress"
            color="primary"
            height="2"
            :value="progressValue"
          ></v-progress-linear>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <v-card
            flat
            tile
            class="pa-3"
          >
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <h4 class="ml-5" style="color: #5B5B5B;">사무실명</h4>
              </v-col>
              <v-col>
                {{ oLawork.wname }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <h4 class="ml-5" style="color: #5B5B5B;">주문번호</h4>
              </v-col>
              <v-col>
                {{ orderInfo.order_code }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <h4 class="ml-5" style="color: #5B5B5B;">주문일자</h4>
              </v-col>
              <v-col>
                {{ strDateFormat2(orderInfo.createdAt) }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <h4 class="ml-5" style="color: #5B5B5B;">결제자</h4>
              </v-col>
              <v-col>
                {{ orderInfo.name }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <h4 class="ml-5" style="color: #5B5B5B;">이메일</h4>
              </v-col>
              <v-col>
                {{ orderInfo.email }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
        class="mt-3 mb-12"
      >
        <v-col cols="12" md="6" sm="8">
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <span class="text-h6 primary--text font-weight-bold">상품정보</span>
          <v-progress-linear
            :indeterminate="isProgress"
            color="primary"
            height="2"
            :value="progressValue"
          ></v-progress-linear>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <v-card
            flat
            tile
            class="pa-3"
          >
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <h4 class="ml-5" style="color: #5B5B5B;">상품명</h4>
              </v-col>
              <v-col>
                {{ orderInfo.good_name }}
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <h4 class="ml-5" style="color: #5B5B5B;">사용인원</h4>
              </v-col>
              <v-col>
                {{ orderInfo.good_qty}}명
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <h4 class="ml-5" style="color: #5B5B5B;">기간</h4>
              </v-col>
              <v-col>
                {{ orderInfo.good_term }}개월
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <h4 class="ml-5" style="color: #5B5B5B;">금액</h4>
              </v-col>
              <v-col>
                {{ numberFormat(orderInfo.pay_price) }}원(VAT 포함)
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="py-3"
            >
              <v-col cols="4">
                <h4 class="ml-5" style="color: #5B5B5B;">결제수단</h4>
              </v-col>
              <v-col>
                {{ orderMethods[orderInfo.method] }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
        class="mt-3 mb-12"
      >
        <v-col cols="12" md="6" sm="8">
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <v-expand-transition>
            <v-card
              tile
              flat
              class="pa-0 ma-0"
            >
              <v-alert
                text
                color="warning"
              >
                <div class="text-subtitle-2" style="font-size: 1.5rem;font-weight: 500;">
                  ※ 입금확인까지 시간이 소요될 수 있습니다.
                </div>
                <div class="text-subtitle-2" style="font-size: 1.5rem;font-weight: 500;">
                  ※ 입금확인 후 재로그인 하시면 정상적으로 시작하실 수 있습니다.
                </div>
                <div class="text-subtitle-2 primary--text" style="font-size: 1.5rem;font-weight: 600;">
                  ※ 결제방식을 변경하시려면 고객센터 02-521-7505 로 연락주시기 바랍니다.
                </div>
                <v-divider
                  class="mt-4 mb-2 warning"
                  style="opacity: 0.22"
                ></v-divider>
                <h6 class="text-h6 font-weight-medium">
                  계좌번호: 하나은행 124-910006-24905
                </h6>
                <h6 class="text-h6 font-weight-medium">
                  예금주: (주)현우엘앤씨
                </h6>
              </v-alert>
            </v-card>
          </v-expand-transition>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
        class="mb-12"
      >
        <v-col cols="12" md="8">
          <v-row
            align="center"
            justify="center"
            class="py-4"
          >
            <v-btn
              x-large
              dark
              depressed
              color="primary"
              class="mr-2 px-12"
              @click="btnConfirmClick"
            >
              <span class="hidden-xs-only text-h5 mx-16 px-16">
                확인
              </span>
              <span class="d-flex d-sm-none text-h5 mx-8 px-8">
                확인
              </span>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" md="6" sm="8">
          <v-card
            tile flat class="pa-3"
            height="100"
          >
            &nbsp;
          </v-card>
        </v-col>
      </v-row>

    </v-card>
  </v-dialog>
</template>

<script>
import sleep from '@/lib/sleep'

// 구분: filters
import numberFormat from '@/filters/numberFormat'
import strDateFormat2 from '@/filters/strDateFormat2'

export default {
  name: 'Payment01',

  props: {
    source: String
  },

  data: () => ({
    dialog: false,
    isProgress: true,
    progressValue: 100,
    mustLogOut: true, // * 확인이나 취소 클릭시 반드시 로그아웃해야하는지 여부
    // 구분: lawork 정보
    oLawork: {
      wname: ''
    },
    // 구분: 패칭된 가장 최근 승인대기 결제정보
    orderInfo: {
      order_code: '',
      createdAt: '',
      name: '',
      email: '',
      method: '',
      good_name: '',
      good_qty: '',
      good_term: '',
      pay_price: 0
    },
    // 구분: 결제수단
    orderMethods: {
      A: '무통장',
      B: '신용카드',
      C: '계좌이체',
      D: '가상계좌',
      E: '쿠폰',
      F: '휴대폰',
      G: '상품권'
    }
  }),

  created () {
    this.dialog = true
  },

  mounted () {
    // 중요: 이 페이지로 왔다는건 이용기간이 만료된것임!
    // 중요한건 어쨓거나 로그인 정보(이메일, 디비코드 같은 필수정보)가 있어야 나머지 작업이 가능하다

    // # 로그인이 필요하므로 로그인 체크
    this.loginCheck()

    // 중요: 로그인으로 넘어온 토큰정보가 있으면 풀어서 사용하고
    if (this.$route.query && this.$route.query.token) {
      this.loginWithToken(this.$route.query.token).then(() => {
        this.getLaworkInfo()
        this.getLastOrder()
      })
    } else {
      // 아니면 기존 토큰이 있는지 봐서 ..
      if (this.$store.state && this.$store.state.ui) {
        this.getLaworkInfo()
        this.getLastOrder()
      }
    }

    sleep(1000).then(() => { this.isProgress = false })
  },

  methods: {
    numberFormat,
    strDateFormat2,
    dummy () {
      console.log('dummy test ~')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (const key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 토큰을 백엔드에서 넘겨받아 로그인 처리하기
    async loginWithToken (token) {
      try {
        if (token) {
          // 로그인 처리 : 토큰 저장 + 토큰을 푼 유저정보를 저장
          await this.$store.dispatch('LOGIN', { token })
        } else {
          throw new Error('Token Not Reponsed : [401]')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 결제페이지는 로그인 이후에나 접근이 가능하다.
    async loginCheck () {
      try {
        if (!this.$store.state.ui) {
          const popE = await this.findParentRefs(this.$parent, 'confirmDialog')
          if (!popE) throw new Error('팝업창을 열 수 없습니다.')
          // 찾았으면 팝업을 연다
          const msg = '[오류]로그인이 필요한 페이지 입니다!'
          await popE.open('오류', msg, { color: 'error', width: 400 })
          // 위 코드는 변수로 받아 true/false를 논할 수 있지만. 여기선 무조건 아래 코드를 실행해야한다.
          await sleep(300).then(() => { this.$router.push(`/`) })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 확인 버튼 클릭 이벤트 핸들러
    btnConfirmClick (to = '') {
      this.dialog = false
      if (!this.mustLogOut) {
        // * 만료일이 남은 경우 -> 이전 페이지로 백시킨다!
        // this.$router.go(-1) // window.history.back() 과 동일한 효과
        this.$router.push('/schedule')
        //
      } else {
        // * 만료일이 지난 경우 -> 로그아웃 시킨다.
        this.$store.dispatch('LOGOUT').then(() => this.$router.push('/')) // * [2022.3.15]로그아웃처리
        //
      }
    },
    async getLaworkInfo () {
      try {
        if (this.$store.state && this.$store.state.ui) {
          // 백앤드에서 로워크정보를 패칭
          const { data } = await this.$axios.get(`auth/getLaworkInfoByDbcode/${this.$store.state.ui.dbcode}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다`)
          this.oLawork = data.laworkInfo
          // console.log(this.$store.state.ui.expDate) // 이걸로 해도 되지만 디비를 거쳐온걸 쓰자
          // ![2022.4.13] 확인/취소 버튼을 클릭했을때 기간이 남은 경우 이전페이지로 이동시키고 기간이 없는 경우 로그아웃 시키는 로직이다.
          if (this.$moment(this.oLawork.expiredAt).startOf('day').diff(this.$moment(), 'days') < 0) {
            // * 만료일이 지났다 -> mustLogOut = true 로 해서 반드시 로그아웃해야 한다.
            this.mustLogOut = true
          } else {
            // * 만료일이 남았다(오늘 + 미래) -> mustLogOut = false 로 해서 뒤로 백하게 한다.
            this.mustLogOut = false
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 가장 최근 승인대기 결제정보 패칭
    async getLastOrder () {
      try {
        if (this.$store.state && this.$store.state.ui) {
          // ? 백앤드에서 최근결제정보 패칭
          const { data } = await this.$axios.get(`auth/getLastOrderByDbcode/${this.$store.state.ui.dbcode}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다`)
          this.orderInfo = data.orderInfo
          //
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
